module.exports = [{
      plugin: require('/Users/hassanalam/Source/personal-website/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/hassanalam/Source/personal-website/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/hassanalam/Source/personal-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
